import { Link, Tooltip, Typography } from "@mui/material";
import React from "react";
import { ItemsEntity, NotificationPatientsEntity } from "../../types";
import { MessageType } from "../FiltersForm";

export const getNotificationInfo = (form: ItemsEntity) => {
  const jsonData = form.notificationPatients[0]?.jsonData || {};
  if (form.messageType == MessageType.TREATMENT_SCHEDULED) {
    return (
      <>
        <Typography>Message Type: {form.messageType}</Typography>
        <Typography>
          Treatment Name: {jsonData.data.treatmentName || "N/A"}
        </Typography>
        <Typography>Date Time: {jsonData.data.time || "N/A"}</Typography>
        <Typography>Address: {jsonData.data.location || "N/A"}</Typography>
        <Typography>
          Timeslot:
          <Link
            target="_blank"
            href={`/time-slots/time-slots-actions/${jsonData.data.timeSlotId}/view`}
          >
            T{jsonData.data.timeSlotId}
          </Link>
        </Typography>
      </>
    );
  }

  if (form.messageType == MessageType.TIME_SLOT_SCHEDULING) {
    return (
      <>
        <Typography>Message Type: {form.messageType}</Typography>
        <Typography>Treatment Name: {jsonData.data.treatmentName}</Typography>
        <Typography>Location: {jsonData.data.location || "N/A"}</Typography>

        <Typography>{jsonData.notification.title}</Typography>
        {jsonData.data.timeslotId && (
          <Typography>
            Timeslot:
            <Link
              target="_blank"
              href={`/time-slots/time-slots-actions/${jsonData.data.timeslotId}/view`}
            >
              T{jsonData.data.timeslotId}
            </Link>
          </Typography>
        )}
      </>
    );
  }

  if (form.messageType == MessageType.TREATMENT_REMINDER) {
    return (
      <>
        <Typography>Message Type: {form.messageType}</Typography>
        <Typography>
          Treatment Name: {jsonData.data.treatmentName || "N/A"}
        </Typography>
        <Typography>Location: {jsonData.data.location || "N/A"}</Typography>
        <Typography>Time: {jsonData.data.time || "N/A"}</Typography>
        {jsonData.data.timeSlotId && (
          <Typography>
            Timeslot:
            <Link
              target="_blank"
              href={`/time-slots/time-slots-actions/${jsonData.data.timeSlotId}/view`}
            >
              T{jsonData.data.timeSlotId}
            </Link>
          </Typography>
        )}
      </>
    );
  }

  if (form.messageType == MessageType.TREATMENT_PASSED) {
    return (
      <>
        <Typography>Message Type: {form.messageType}</Typography>
        <Typography>
          Treatment Name: {jsonData.data.treatmentName || "N/A"}
        </Typography>
        <Typography>Location: {jsonData.data.location || "N/A"}</Typography>
        <Typography>Time: {jsonData.data.time || "N/A"}</Typography>
        {jsonData.data.timeSlotId && (
          <Typography>
            Timeslot:
            <Link
              target="_blank"
              href={`/time-slots/time-slots-actions/${jsonData.data.timeSlotId}/view`}
            >
              T{jsonData.data.timeSlotId}
            </Link>
          </Typography>
        )}
      </>
    );
  }

  if (form.messageType == MessageType.CUSTOM_PUSH) {
    return (
      <>
        <Typography>{form?.content}</Typography>
      </>
    );
  }
  if (form.messageType == MessageType.DAILY_ACTIVITY) {
    return (
      <>
        <Typography>Name: {jsonData?.data.name || "N/A"}</Typography>
        <Typography>
          Description: {jsonData.data?.description || "N/A"}
        </Typography>
      </>
    );
  }

  if (
    form.messageType == MessageType.PRIOR_TREATMENT ||
    form.messageType == MessageType.POST_TREATMENT
  ) {
    return (
      <>
        <Typography>Title: {jsonData?.notification.title || "N/A"}</Typography>
        <Typography>
          Description: {jsonData.notification?.body || "N/A"}
        </Typography>
      </>
    );
  }
  if (
    form.messageType == MessageType.POST_QUESTIONNAIRE ||
    form.messageType == MessageType.PRIOR_QUESTIONNAIRE
  ) {
    return (
      <>
        <Typography>Name: {jsonData?.data.name || "N/A"}</Typography>
        {jsonData.data.questionerId && (
          <Typography>
            Questioner:
            <Link
              target="_blank"
              href={`/questionnaires/${
                jsonData.data.type === "mcq" ? "multi" : "open"
              }/${jsonData.data.questionerId}?mode=view`}
            >
              Q{jsonData.data.questionerId}
            </Link>
          </Typography>
        )}
      </>
    );
  }
  return <Typography>{form?.content}</Typography>;
};

export const getRatingInfo = (value: NotificationPatientsEntity) => {
  if (value?.jsonData?.data?.options) {
    return (
      <Tooltip title={value?.jsonData?.data?.options?.comment}>
        <Typography>{value?.jsonData?.data?.options?.rating}/5</Typography>
      </Tooltip>
    );
  }
  return "Not Responded";
};
