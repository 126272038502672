import { Checkbox, Tooltip, Typography } from "@mui/material";
import DataTable from "app/components/DataTable";
import DeleteModal from "app/components/DeleteModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { getFormattedDateTime } from "utils/helpers";
import {
  selectList,
  selectLoading,
  selectQuery,
} from "../../../../redux/selector";
import { actions } from "../../../../redux/slice";
import { useStyles } from "./styles";

const TreatmentBankDetail = ({ disable, treatmentBankId }) => {
  const styles = useStyles();
  const [openDelete, setDelete] = useState(false);
  const listitems = useSelector(selectList);
  const { orderField, orderDirection } = useSelector(selectQuery);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleDeleteClose = () => {
    setDelete(false);
  };

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const handleDeleteBankTreatment = (row) => {
    dispatch(actions.deleteTreatmentBankTreatment(row));
  };
  const handleEditBankTreatment = (row) => {
    dispatch(actions.editTreatmentBankTreatment(row));
    dispatch(actions.getOptionInstructorOptions(row.treatmentId));
  };

  const loading = useSelector(selectLoading);

  const handleCheckboxChange = (e, rowIndex) => {
    dispatch(
      actions.setEditedCheckboxItems({ index: rowIndex, value: !e.isMust })
    );
  };

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.TREATMENT_BANK_LIST];
  const columns = [
    {
      id: "treatmentTypeName",
      label: t(`TREATMENT_BANKS.TREATMENT_TYPE_NAME`),
      minWidth: 180,
      maxWidth: 280,
    },

    {
      id: "description",
      label: t(`TREATMENT_BANKS.DESCRIPTION`),
      minWidth: 250,
      maxWidth: 250,
    },
    {
      id: "groupIndividual",
      label: t(`TREATMENT_BANKS.GROUP_INDIVIDUAL`),
      minWidth: 100,
      maxWidth: 100,
    },
    {
      id: "numberOfSession",
      label: t(`TREATMENT_BANKS.NUMBER_OF_SESSIONS`),
      minWidth: 100,
      maxWidth: 100,
    },

    {
      id: "treatmentIsMust",
      label: t(`TREATMENT_BANKS.IS_MUST`),
      minWidth: 80,
      maxWidth: 80,
      format: (value) => {
        return (
          <Checkbox
            color="info"
            value={""}
            checked={value.isMust}
            disabled={true}
          />
        );
      },
    },
    {
      id: "maximumPerPatient",
      label: t(`TREATMENT_BANKS.MAXIMUM_PER_PATIENT`),
      minWidth: 80,
      maxWidth: 80,
    },
    {
      id: "optionalInstructor",
      label: t(`TREATMENT_BANKS.OPTIONAL_INSTRUCTORS`),
      minWidth: 250,
      maxWidth: 250,
      format: (row, index) => {
        const list = row.instructorList
          .filter((f) => row.optionalInstructor?.includes(f.id))
          .map((m) => `${m?.firstName} ${m?.lastName}`)
          .join(",");
        if (!list.length) {
          const removedList = row?.allOptionalInstructor
            ?.map((m) => {
              return `${m?.treatmentOptionalInstructor?.firstName} ${m?.treatmentOptionalInstructor?.lastName}`;
            })
            .join(",");
          return (
            <Tooltip title={`Instrcutors Removed: ${removedList}`}>
              <Typography
                style={{ opacity: 0.5 }}
                className={styles.instructorListCell}
              >
                {removedList}
              </Typography>
            </Tooltip>
          );
        }
        return (
          <Tooltip title={list}>
            <Typography className={styles.instructorListCell}>
              {list}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "startDate",
      label: t(`TREATMENT_BANKS.START_DATE`),

      minWidth: 120,
      maxWidth: 180,
      format: (row, index) => {
        return getFormattedDateTime(row.startDate, "dd-MM-yyyy");
      },
    },
    {
      id: "endDate",
      label: t(`TREATMENT_BANKS.END_DATE`),
      minWidth: 120,
      maxWidth: 180,
      format: (row, index) => {
        return getFormattedDateTime(row.endDate, "dd-MM-yyyy");
      },
    },
  ];

  return (
    <div className={styles.tableDiv}>
      <DataTable
        loading={loading}
        orderDirection={orderDirection}
        orderField={orderField}
        setQuery={setPageQuery}
        Actions={hasFullAccess ? "Delete,Edit" : ""}
        disableActions={disable ? "Delete" : ""}
        rows={listitems["editItems"].filter((item) => item.action !== "delete")}
        columns={columns}
        scrollClass={styles.dataTableDiv}
        checkboxChange={handleCheckboxChange}
        deleteAction={handleDeleteBankTreatment}
        editAction={handleEditBankTreatment}
      />
      <DeleteModal
        title={t("TREATMENT_BANKS.DELETE_ORGANIZATION")}
        body={"TREATMENT_BANKS.CONFIRM_DELETE_ORGANIZATION"}
        open={openDelete}
        buttonText={t("BUTTONS.DELETE")}
        handleClose={handleDeleteClose}
      />
    </div>
  );
};

export default TreatmentBankDetail;
