import { Tooltip } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import { DownloadButton } from "app/components/Button/DownloadButton";
import DataTable from "app/components/DataTable";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import { ScrollableView } from "app/components/ScrollableView";
import Topbar from "app/containers/Dashboard/components/topbar";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { getFormattedDateTime } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import OpenQuestionerModal from "../../components/OpenQuestionerModal";
import { selectList, selectLoading, selectQuery } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

const QuestionnaireList = ({ patientId }: { patientId?: number }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { meta, items } = useSelector(selectList);

  const {
    q,
    orderField,
    orderDirection,
    type,
    organizationId,
    departmentId,
    bankId,
    from,
    to,
    treatmentPhase,
  } = useSelector(selectQuery);

  useEffect(() => {
    dispatch(actions.getList({ patientId }));
  }, [
    type,
    orderField,
    orderDirection,
    q,
    patientId,
    organizationId,
    departmentId,
    bankId,
    from,
    to,
    treatmentPhase,
  ]);

  const { t } = useTranslation();
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const loading = useSelector(selectLoading);
  const shownColumns = !!patientId
    ? [
        "created_at",
        "updated_at",
        "name",
        "type",
        "treatmentPhase",
        "totalQuestions",
        "totalGrade",
      ]
    : [
        "created_at",
        "updated_at",
        "patientName",
        "name",
        "type",
        "treatmentPhase",
        "totalQuestions",
        "totalGrade",
        "organization.name",
        "department.departmentName",
        "bankName",
      ];
  const columns = [
    {
      id: "created_at",
      label: t("QUESTIONNAIRE_RESULT.DATE_SENT"),
      sortValue: "selector.createdAt",
      format: (val) => {
        return getFormattedDateTime(val.createdAt);
      },
    },
    {
      id: "updated_at",
      label: t("QUESTIONNAIRE_RESULT.DATE_ANSWERED"),
      sortValue: "selector.answeredAt",
      format: (val) => {
        return getFormattedDateTime(val.answeredAt);
      },
    },
    {
      id: "patientName",
      label: t("QUESTIONNAIRE_RESULT.PATIENT_NAME"),
      sortValue: "patient.firstName",
      format: (val) => {
        return `${val.patient.firstName} ${val.patient.lastName}`;
      },
    },
    {
      id: "name",
      label: t("QUESTIONNAIRE_RESULT.QUESTIONNAIRE_NAME"),
      sortValue: "questionnaire.name",
      format: (val) => {
        return `${val?.questionnaire?.name}`;
      },
    },
    {
      id: "type",
      label: t("QUESTIONNAIRE_RESULT.QUESTIONNAIRE_TYPE"),
      sortValue: "selector.type",
    },
    {
      id: "treatmentPhase",
      label: t("QUESTIONNAIRE_RESULT.TREATMENT_PHASE"),
      format: (val) => {
        if (!val.isWellBeing) {
          return t("QUESTIONNAIRE_RESULT.TREATMENT_PHASE_GENERAL");
        }
        return val.status === "BEFORE"
          ? t("QUESTIONNAIRE_RESULT.TREATMENT_PHASE_BEFORE")
          : t("QUESTIONNAIRE_RESULT.TREATMENT_PHASE_AFTER");
      },
    },
    {
      id: "totalQuestions",
      label: t("QUESTIONNAIRE_RESULT.NO_OF_QUESTION"),
      sortValue: "questionnaire.totalQuestions",
      format: (val) => {
        return `${val?.questionnaire?.totalQuestions}`;
      },
    },
    {
      id: "totalGrade",
      label: t("QUESTIONNAIRE_RESULT.TOTAL_GRADE"),
      sortValue: "selector.score",
      format: (val) => {
        return val?.score;
      },
    },
    {
      id: "organization.name",
      label: t("PATIENTS.ORGANIZATION_NAME"),
      sortValue: "organization.name",
      minWidth: 200,
      maxWidth: 300,
      format: (val) => {
        return val?.patient.organization?.name;
      },
    },
    {
      id: "department.departmentName",
      label: t("PATIENTS.DEPARTMENT_NAME"),
      sortValue: "department.departmentName",
      minWidth: 200,
      maxWidth: 300,
      format: (val) => {
        return val?.patient.department?.departmentName;
      },
    },
    {
      id: "bankName",
      label: t("PATIENTS.BANK_NAME"),
      sortValue: "bank.name",
      minWidth: 200,
      maxWidth: 300,
      format: (val) => {
        return val.patient.bank.name;
      },
    },
  ].filter((f) => shownColumns.includes(f.id));
  const handleViewAction = (data: any) => {
    dispatch(actions.toggalModal({ id: data.id }));
  };

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPage({ patientId }));
    }
  };
  const isLoading = false;

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasNoAccess, hasFullAccess } =
    featurePermissions[PermissionFeature.QUESTIONNAIRES_RESULTS];
  const { height } = useWindowDimensions();
  if (hasNoAccess) {
    return (
      <div className={styles.bg_color}>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }
  return (
    <>
      <ScrollableView
        loadMore={getNextPage}
        hasMoreItems={hasMoreItems}
        height={Number(patientId) > 0 ? 400 : height - 390}
      >
        <DataTable
          loading={Boolean(loading)}
          orderDirection={orderDirection}
          orderField={orderField}
          setQuery={setPageQuery}
          rows={items}
          columns={columns}
          onRowDoubleClick={handleViewAction}
          page="questionnaireResultPage"
          isPointerCursor={true}
        />
      </ScrollableView>
      <BottomActions
        chipBoxes={[
          {
            label: t("QUESTIONNAIRE_RESULT.LIST_TOTAL_ITEMS"),
            count: meta.totalItems,
          },
        ]}
        disabled={isLoading}
      >
        <Tooltip title={"Only less than  100 records can be extracted"}>
          <DownloadButton
            // disabled={!(meta.totalItems < 100)}
            label={t("BUTTONS.EXTRACT_RESULTS")}
            path={`/v1/questionnaires-results/download`}
            params={{
              type,
              orderField,
              orderDirection,
              q,
              patientId,
              organizationId,
              departmentId,
              bankId,
              from,
              to,
              treatmentPhase,
            }}
          />
        </Tooltip>
      </BottomActions>
      <OpenQuestionerModal />
    </>
  );
};
export default QuestionnaireList;
